import Hero from '@organisms/JobsHero/JobsHero';
import Question1 from '@organisms/Matchmaker/Question1';
import Sections from '@organisms/Sections/Sections';
import PropTypes from 'prop-types';

function Home({ page }) {
  return (
    <>
      <Hero title={page.title} subtitle={page.subtitle} image={page.image}>
        <Question1 />
      </Hero>
      <Sections sections={page.sections} />
    </>
  );
}

Home.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string,
      placeholder: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }).isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, typename: PropTypes.string })
    ),
  }),
};

export default Home;
