import HomePageHeroLifeLine from '@svg/jobs-hero-lifeline.svg';
import HomePageHeroLifelinesDesktop from '@svg/jobs-hero-lifelines-desktop.svg';
import HomePageHeroLifelinesMobile from '@svg/jobs-hero-lifelines-mobile.svg';

import styles from './HeroLifelines.module.scss';

export function HeroLifelines() {
  return (
    <div className={styles.container}>
      <HomePageHeroLifelinesDesktop className={styles.heroLifelinesDesktop} />
      <HomePageHeroLifelinesMobile className={styles.heroLifelinesMobile} />
    </div>
  );
}

export function SecondaryLifeline() {
  return <HomePageHeroLifeLine className={styles.homepageHeroLifeLine} />;
}
