import Button from '@atoms/Button/Button';
import { BG_CREME } from '@constants';
import PagepartBase from '@molecules/PagepartBase/PagepartBase';
import classNames from 'classnames';
import Image from 'next/legacy/image';
import PropTypes from 'prop-types';

import styles from './HighlightedPagesSection.module.scss';

function HighlightedPagesSection({ highlightedPages, id }) {
  return (
    <PagepartBase id={id} backgroundColor={BG_CREME}>
      <ul className={classNames(styles.list, 'u-list-clean')}>
        {highlightedPages.map(({ title, text, image, slug }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li className={styles.card} key={`${title}-${index}`}>
            <article className={styles.article}>
              <div className={styles.imageContainer}>
                <Image
                  src={image.url}
                  placeholder="blur"
                  blurDataURL={image.placeholder}
                  alt={title}
                  layout="fill"
                  objectFit="cover"
                  sizes="(min-width: 640px) 719px, (min-width: 768px) 475px, (min-width: 1024px) 405px, 100vw"
                />
              </div>
              <div className={styles.content}>
                <h1 className="u-typography-heading-3">{title}</h1>
                <p className="u-mb-4">{text}</p>
                <Button
                  label="Lees meer"
                  href={slug}
                  extraClasses={classNames(styles.button, styles.label)}
                />
              </div>
            </article>
          </li>
        ))}
      </ul>
    </PagepartBase>
  );
}

HighlightedPagesSection.propTypes = {
  id: PropTypes.string,
  highlightedPages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
        placeholder: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }).isRequired,
      slug: PropTypes.string,
    })
  ),
};

export default HighlightedPagesSection;
