import RichText from '@atoms/RichText/RichText';
import ArtOfGrowingOld from '@svg/art-of-growing-old.svg';
import HomepageIntroContainerTop from '@svg/corporate-homepage-intro-container-top.svg';
import HomepageIntroImageBottom from '@svg/corporate-homepage-intro-image-bottom.svg';
import HomepageIntroImageTop from '@svg/corporate-homepage-intro-image-top.svg';
import HomepageTopContainerBottomDesktop from '@svg/corporate-homepage-top-container-bottom-desktop.svg';
import HomepageTopContainerBottomMobile from '@svg/corporate-homepage-top-container-bottom-mobile.svg';
import classNames from 'classnames';
import Image from 'next/legacy/image';
import PropTypes from 'prop-types';

import styles from './HomeHero.module.scss';
import QuickLinks from './QuickLinks';

function HomeHero({ title, subtitle, introText, image, quickMenuItems }) {
  return (
    <section className={styles.hero}>
      <div className={styles.topContainer}>
        {/* Svg border */}
        <HomepageTopContainerBottomMobile
          className={styles.homepageTopContainerBottomMobile}
        />
        {/* Svg border */}
        <HomepageTopContainerBottomDesktop
          className={styles.homepageTopContainerBottomDesktop}
        />
        <div className={classNames(styles.topContentContainer, 'u-container')}>
          <div className={styles.titleContent}>
            <h1>{title}</h1>
            <p className={styles.subtitle}>{subtitle}</p>
          </div>
          <div className={styles.quickMenuContainer}>
            <QuickLinks links={quickMenuItems} />
          </div>
        </div>
      </div>

      <div className={styles.introWrapper}>
        {/* Svg border */}
        <HomepageIntroContainerTop
          className={styles.homepageIntroContainerTop}
        />
        <div className={styles.introContainer}>
          <div className={styles.imageContainer}>
            <svg className={styles.clipPathSvg}>
              <clipPath id="clip-path" clipPathUnits="objectBoundingBox">
                <path d="M0.419,0.867 C0.185,0.759,-0.078,0.682,0.022,0.316 C0.08,0.101,0.411,-0.033,0.615,0.007 C0.733,0.029,0.866,0.115,0.93,0.247 C0.995,0.379,1,0.533,0.986,0.677 C0.956,0.822,0.914,0.928,0.77,0.985 C0.625,1,0.532,0.919,0.419,0.867" />
              </clipPath>
            </svg>
            <Image
              src={image.url}
              width={image.width}
              height={image.height}
              alt=""
              className={styles.image}
            />
            <ArtOfGrowingOld
              className={styles.artOfGrowingOld}
              title="De kunst van gelukkig ouder worden"
            />
            {/* Svg border */}
            <HomepageIntroImageTop className={styles.homepageIntroImageTop} />
            {/* Svg border */}
            <HomepageIntroImageBottom
              className={styles.homepageIntroImageBottom}
            />
          </div>
          <div className={styles.introTextContainer}>
            <RichText text={introText} />
          </div>
        </div>
      </div>
    </section>
  );
}

HomeHero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  introText: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
    placeholder: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  quickMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({
        icon: PropTypes.string,
      }),
      pages: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string,
        })
      ),
    })
  ),
};

export default HomeHero;
