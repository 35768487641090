import getOrCreateCustomerId from '@lib/customer';
import getConfigProps from '@lib/getConfigProps';
import { getCorporateHomePage, getJobsHomePage } from '@lib/prepr';
import CorporateHome from '@organisms/CorporateHome/Home';
import JobsHome from '@organisms/JobsHome/Home';
import { isJobsDomain } from '@utils/getDomain';
import PropTypes from 'prop-types';

function HomePage({ page, jobsDomain }) {
  if (jobsDomain) {
    return <JobsHome page={page} />;
  }
  return <CorporateHome page={page} />;
}

HomePage.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    highlightedPages: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string,
          placeholder: PropTypes.string,
          width: PropTypes.number,
          height: PropTypes.number,
        }).isRequired,
        slug: PropTypes.string,
      })
    ),
    sections: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, typename: PropTypes.string })
    ),
  }),
  jobsDomain: PropTypes.bool.isRequired,
};

export async function getServerSideProps(ctx) {
  const { req, locale, preview = false } = ctx;

  const customerId = getOrCreateCustomerId(req);
  const { host } = req.headers;
  const jobsDomain = isJobsDomain(host);
  let homepage;

  if (jobsDomain) {
    homepage = getJobsHomePage({ host, locale, customerId, preview });
  } else {
    homepage = getCorporateHomePage({ host, locale, customerId, preview });
  }

  const [
    page,
    {
      props: { config },
    },
  ] = await Promise.all([homepage, getConfigProps(ctx)]);

  if (!page) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      preview,
      page,
      config,
      host,
      customerId,
      jobsDomain,
    },
  };
}

export default HomePage;
