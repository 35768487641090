import AnimatedBlobImage from '@atoms/AnimatedBlobImage/AnimatedBlobImage';
import {
  HeroLifelines,
  SecondaryLifeline,
} from '@atoms/HeroLifelines/HeroLifelines';
import TopContainerBottomDesktop from '@svg/jobs-hero-top-container-bottom-desktop.svg';
import TopContainerBottomMobile from '@svg/jobs-hero-top-container-bottom-mobile.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './JobsHero.module.scss';

function JobsHero({ title, subtitle, image, children, modifier }) {
  return (
    <section className={classNames(styles.hero, styles[modifier])}>
      <div className={styles.topContainer}>
        {/* Background lifelines */}
        <HeroLifelines />

        {/* Svg border */}
        <TopContainerBottomDesktop
          className={styles.topContainerBottomDesktop}
        />

        {/* Svg border */}
        <TopContainerBottomMobile className={styles.topContainerBottomMobile} />

        <div className={classNames(styles.topContentContainer, 'u-container')}>
          {/* Background lifelines */}
          <SecondaryLifeline />

          <div className={styles.titleContent}>
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
            <h1 className={styles.title}>{title}</h1>
          </div>

          <div className={styles.content}>{children}</div>

          <div className={styles.imageContainer}>
            <AnimatedBlobImage image={image} />
          </div>
        </div>
      </div>
    </section>
  );
}

JobsHero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
    placeholder: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  modifier: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default JobsHero;
