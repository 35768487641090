import Tag from '@atoms/Tags/Tag';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './MatchMakerQuestion.module.scss';

export default function MatchMakerQuestion({
  number,
  total,
  question,
  children,
  extraClasses,
}) {
  return (
    <div className={classNames(styles.card, extraClasses)}>
      <Tag title={`Vraag ${number} van ${total}`} extraClasses={styles.tag} />

      <p className={styles.title}>{question}</p>

      <div className={styles.navigationContainer}>{children}</div>
    </div>
  );
}

MatchMakerQuestion.propTypes = {
  number: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  extraClasses: PropTypes.string,
  children: PropTypes.node.isRequired,
};
