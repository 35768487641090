import Button from '@atoms/Button/Button';
import MatchMakerQuestion from '@molecules/MatchMaker/MatchMakerQuestion';
import PropTypes from 'prop-types';

import styles from './Matchmaker.module.scss';

export default function Question1({ handleNavigation, extraClasses }) {
  return (
    <MatchMakerQuestion
      number={1}
      total={7}
      question="Klaar om te ontdekken of Vitalis bij je past?"
      extraClasses={extraClasses}
    >
      <div className={styles.ctaContainer}>
        <Button
          label="Ja, natuurlijk"
          href="/matchmaker/2"
          size="smallMobile"
          onClick={handleNavigation}
        />

        <Button
          label="Ik twijfel nog"
          href="/ik-twijfel-nog"
          size="smallMobile"
          variant="text"
          onClick={handleNavigation}
        />
      </div>
    </MatchMakerQuestion>
  );
}

Question1.propTypes = {
  handleNavigation: PropTypes.func,
  extraClasses: PropTypes.string,
};
