import { imageType } from '@lib/proptypes';
import Image from 'next/legacy/image';

import styles from './AnimatedBlobImage.module.scss';

/* 
  Animated blob image is ABSOLUTE positioned
  So make sure it has a relative container with correct ratio
*/
export default function AnimatedBlobImage({ image }) {
  return (
    <figure className={styles.figure}>
      <svg className={styles.clipPathSvg}>
        <clipPath id="clip-path" clipPathUnits="objectBoundingBox">
          <path d="M0.504,0.061 C0.257,0.1,-0.01,0.099,0,0.503 C0.006,0.74,0.289,0.977,0.493,0.998 C0.609,1,0.755,0.963,0.847,0.848 C0.939,0.733,0.995,0.582,0.999,0.426 C1,0.269,0.988,0.148,0.864,0.046 C0.741,-0.056,0.624,0.042,0.504,0.061" />
        </clipPath>
      </svg>

      <div className={styles.image}>
        <Image
          key={image.url}
          src={image.url}
          placeholder="blur"
          blurDataURL={image.placeholder}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          alt=""
          sizes="(min-width: 1024px) 684px"
        />
      </div>
    </figure>
  );
}

AnimatedBlobImage.propTypes = {
  image: imageType,
};
